.header {
  max-width: 1170px;
  margin: auto;
  background-color: #4e4e4e99;
  border-radius: 14px;
  display: flex;
  align-items: center;
  border: 1px solid #a0a0a0;
  transition: background-color 0.3s ease;
  backdrop-filter: blur(10px);
  width: calc(100% - 60px);
}
.headercontain {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  width: 100%;
}
.header_container {
  width: 100%;
  position: fixed;
  top: 26px;
  z-index: 1000;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  text-align: left;
}
.navigation ul {
  padding-left: 20px !important;
}

.navigation li {
  display: inline-block;
  margin-right: 20px;
  color: #fff;
}

.navigation li a {
  color: #fff; /* White text */
  text-decoration: none;
}

.navigation li:hover a {
  opacity: 0.8; /* Slight opacity change on hover */
}

.content {
  flex: 1; /* Take up remaining space */
  padding: 20px;
  text-align: center;
}

.content h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.content button {
  background-color: #007bff; /* Blue button */
  color: #fff; /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

.content button:hover {
  background-color: #0069d9; /* Darker blue on hover */
}
.my-container {
  background-image: url("../assets/Images/Favicon_NoBg.svg"); /* Assuming backgroundSVG is a string */
  background-repeat: no-repeat; /* Optional: control how the image repeats */
  background-size: cover; /* Optional: control the image size */
  width: 100%;
  height: 100%;
}
.logo {
  width: 75px;
  height: auto;
  margin-right: 16px;
}

.ordernow {
  background-color: #9a1d29 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #af525b !important;
  cursor: pointer;
  height: 42px;
  width: 130px;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}
.ordernow:hover {
  background-color: #79232b !important;
}
.ordernow2 {
  background-color: #9a1d29 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #af525b !important;
  cursor: pointer;
  height: 42px;
  width: 130px;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}
.ordernow2:hover {
  background-color: #79232b !important;
}
.ordernow21 {
  background-color: #9a1d29 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #af525b !important;
  cursor: pointer;
  height: 42px;
  width: 100%;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}
.ordernow21:hover {
  background-color: #79232b !important;
}
.ordernow3 {
  background-color: #9a1d29 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #af525b !important;
  cursor: pointer;
  height: 42px;
  width: 130px;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 20px;
}
.orderno3:hover {
  background-color: #79232b !important;
}
.ordernow4 {
  background-color: #9a1d29 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #af525b !important;
  cursor: pointer;
  height: 42px;
  width: 130px;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}
.ordernow4:hover {
  background-color: #79232b !important;
}
.divider_std {
  background-color: #9c9c9c;
  height: 23px !important;
  width: 0.4px;
}
.center_align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.listbutton {
  font-size: 15px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif !important;
}
.listbutton:hover {
  font-weight: bold !important;
}
.banner {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/Images/Hero.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  color: white;
  text-align: center;
}
.banner-content {
  max-width: 1170px;
  margin: 0px auto;
  display: flex;
  width: 100%;
  height: max-content;
}
.touch {
  font-size: 70px;
  margin: 0px;
  line-height: 56px;
}
.screen {
  font-size: 70px;
  font-weight: bold;
  margin: 0px;
  line-height: 56px;
}
.feildtext {
  max-width: 414px;
  font-size: 14px;
  line-height: 21px;
  margin-left: auto;
  text-align: right;
}
.item1 {
  padding: 140px 0px 0px 0px;
}
.item2 {
  padding: 143px 0px 0px 0px;
}
.arrow {
  width: 75px;
  height: 75px;
}
.arrayButton {
  width: 58px !important;
  min-width: 58px !important;
  height: 58px !important;
  padding: 0px !important;
  border-radius: 100% !important;
  background-color: #9b9a9aa1 !important;
  backdrop-filter: blur(10px);
}
.buttonposition {
  position: absolute;
  bottom: 36px;
  animation: moveUpDown 2s ease infinite alternate;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0); /* Start position */
  }
  25% {
    transform: translateY(6px); /* Move down */
  }
  50% {
    transform: translateY(6px); /* Stay at bottom position */
  }
  75% {
    transform: translateY(0); /* Move up */
  }
  100% {
    transform: translateY(0); /* End position */
  }
}
.feildtext2 {
  max-width: 375px;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
}
.item3 {
  padding-top: 60px;
}
.item4 {
  padding-top: 60px;
}
.about_company {
  margin-top: 16px !important;
  border: 2px solid rgba(154, 29, 41, 1) !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  padding: 10px 12px 7px 12px !important;
  color: #000 !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
}
.about_company:hover {
  background-color: #e1e1e1 !important;
}
.customfeild_text {
  font-size: 32px !important;
  font-weight: 600 !important;
  text-align: left;
  max-width: 560px;
  margin-top: 10px;
}
.customfeild_text2 {
  font-size: 27px !important;
  font-weight: 600 !important;
  text-align: left;
  max-width: 460px;
  margin-top: 10px;
  padding-left: 20px;
}
.product {
  background-color: rgba(154, 29, 41, 1);
  color: #fff !important;
  max-width: 1170px;
  margin: auto;
  width: 100%;
  border-radius: 20px;
  margin: 0px auto 0px auto;
}
.OverProduct {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: left;
}
.touch1 {
  font-size: 14px;
  line-height: 21px;
}
.screen1 {
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
}
.item7 {
  text-align: right;
  display: flex;
  justify-content: end;
}
.product-content {
  width: 100%;
  padding: 40px 40px 16px 40px;
}
.imagesectionproduct {
  padding: 0px 40px 20px 40px;
}
.findoutmore {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  align-items: center !important;
  padding: 10px 12px 7px 12px !important;
  color: #000 !important;
  width: 100%;
  font-weight: bold !important;
  text-align: left !important;
  margin-bottom: 10px !important;
  font-family: "Montserrat", sans-serif !important;
}
.rightArrow {
  width: 26px;
  height: 25px;
}
.findoutmore:hover {
  background-color: #e1e1e1 !important;
}
.product2 {
  background-color: rgba(154, 29, 41, 1);
  color: #fff !important;
  max-width: 1170px;
  margin: auto;
  width: 100%;
  border-radius: 20px;
  margin: 60px auto 0px auto;
  padding: 54px 40px;
}
.partition {
  font-size: 28px;
  text-align: left;
  max-width: 329px;
  font-weight: bold;
}
.item7 {
  display: flex;
  align-items: center;
  justify-content: end;
}
.icon_container {
  border-radius: 20px;
  height: 140px;
  width: 147px;
  border: 2px solid #fff;
  align-items: center;
  display: flex;
  justify-content: center;
}
.OverProduct2 {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  font-weight: 400;
  text-align: center;
  margin: 3px 0px 0px;
}
.icon_custom {
  width: 48px;
  height: auto;
}
.containerProduct {
  padding-top: 100px;
}
.feildtext9 {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
}
.banner-content6 {
  max-width: 1170px;
  margin: 0px auto;
  display: flex;
  width: 100%;
}
.banner-content3 {
  max-width: 1170px;
  margin: 0px auto;
  display: flex;
  width: 100%;
  height: max-content;
}
.item9 {
  padding-right: 20px;
}
.item10 {
  padding-left: 20px;
}
.container_image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px !important;
}
.container_image img {
  transition: transform 0.5s ease; /* Transition for scale changes */
}

.container_image img:hover {
  transform: scale(1.1); /* Scale up by 10% on hover */
}
.industial_cont {
  position: relative;
  top: -100px;
  height: 2px;
  top: -52px;
  padding: 0px 21px;
}
.partition4 {
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  color: #fff;
  margin: 0px;
}
.plus_minus_contain {
  display: flex;
  align-items: center;
  justify-content: end;
}
.industrycontain {
  align-items: center;
  display: flex;
}
.item20 {
  padding-top: 10px;
}
.item21 {
  padding-top: 10px;
}
.feildtext4 {
  /* max-width: 375px; */
  font-size: 14px;
  line-height: 21px;
  text-align: left;
}
.footer {
  background-color: rgba(154, 29, 41, 1);
  width: 100%;
}
.footerProduct {
  background-color: rgba(154, 29, 41, 1);
  color: #fff !important;
  max-width: 1170px;
  margin: auto;
  width: 100%;
  margin: 60px auto 0px auto;
  padding: 60px 0px;
}
.listbutton2 {
  font-size: 14px !important;
  font-weight: 400 !important;
  cursor: pointer;
  color: #fff !important;
  margin-top: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}
.buttoncontain {
  display: grid;
}
.logoDesign {
  width: 400px;
  height: auto;
  margin-bottom: 10px;
}
.instaIcon {
  width: 24px;
  height: 24px;
}
.LinkedinIcon {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.toughscreenInfo {
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #fff !important;
  text-transform: capitalize !important;
  text-decoration: underline;
  margin: 0px 0px 0px 0px !important;
  letter-spacing: 1px;
}
.divider_std_footer {
  background-color: #b86069;
  margin: 20px 0px !important;
  width: 100%;
  height: 0.4px !important;
}
.endtext {
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
}
.logoheader {
  width: 100%;
  height: 98px;
}
.model {
  border-radius: 20px !important;
}
.titleModel {
  font-size: 22px !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
  padding: 0px 10px;
}
.subtitleModel {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  padding: 0px 10px;
}
.textLabelmodel {
  font-size: 14px !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
  color: #000 !important;
  padding-top: 10px !important;
}
.textfieldCustom > div {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  border-radius: 8px !important;
}
.textfieldCustom {
  margin-top: 2px !important;
}
.textfieldCustom > p {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
}
.buttonSend {
  margin-top: 10px !important;
  background-color: #9a1d29 !important;
  color: #fff !important;
  border-radius: 10px !important;
  border: 2px solid #af525b !important;
  cursor: pointer !important;
  height: 34px;
  width: 100%;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}
.customBox {
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #fff; /* Replace with your background color */
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2); /* Replace with your box shadow */
  padding: 30px 26px !important; /* Adjust padding as needed */
}
.customscroll {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 10px;
}

#style-15::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: none;
  border-radius: 10px;
}

#style-15::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-15::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: none;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#9a1d29),
    to(#76131c),
    color-stop(0.6, #e4606c)
  );
}
.titleModel2 {
  font-size: 26px !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
  padding: 0px 10px 10px 10px;
  line-height: 26px !important;
}
.subtitleModel2 {
  font-size: 14px !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
  padding: 8px 10px 0px 10px;
  display: flex;
  align-items: center;
}
.subtitleModel6 {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  background-color: #fff;
  color: #000;
  padding: 10px;
}
.customToolTip {
  background-color: #fff !important;
}

.customBox2 {
  border-radius: 20px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 600px;
  margin-right: 30px;
  background-color: #fff; /* Replace with your background color */
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2); /* Replace with your box shadow */
  padding: 30px 26px !important; /* Adjust padding as needed */
}

.customBoxmobile {
  border-radius: 20px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 340px;
  margin-right: 30px;
  background-color: #fff; /* Replace with your background color */
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2); /* Replace with your box shadow */
  padding: 80px 20px 20px 20px !important; /* Adjust padding as needed */
}
.customBox3 {
  border-radius: 24px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 400px;
  height: 491px;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2); /* Replace with your box shadow */
  padding: 0px !important; /* Adjust padding as needed */
}
.modelContainerCustom {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100% !important;
  transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: center;
}
.modelContainerCustomset {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100% !important;
  transform: translate(-50%, -50%);
  display: flex !important;
  justify-content: center;
}
.scrollsetmobile {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
.subtitleModel70 {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  padding: 0px 10px 8px 10px;
}
.MuiTooltip-tooltip {
  background-color: #fff !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.dispayhide {
  display: none !important;
}
.dispayhideMobile {
  display: block !important;
}
.aboutLetter {
  justify-content: center;
  margin-top: 64px !important;
}

@media (max-width: 1200px) {
  .divider_std {
    margin-right: 10px !important;
  }
  .menuCustom {
    display: block !important;
  }
  .ordernow {
    height: 38px;
    width: 120px;
    font-size: 13px !important;
    right: 16px;
    position: absolute !important;
  }
  .logo {
    margin-right: 14px;
  }
  .headercontain {
    padding: 10px 14px;
  }
  .about_company {
    width: 100%;
  }
  .feildtext2 {
    max-width: 100%;
    margin: 0px 0px 30px 0px;
  }
  .item1 {
    padding: 90px 30px 0px 30px;
    height: max-content;
  }
  .item2 {
    padding: 0px 30px 0px 30px;
  }
  .containerProduct {
    padding: 62px 30px 10px 30px;
  }
  .customfeild_text {
    max-width: 100%;
    font-size: 28px !important;
  }
  .banner-content3 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  .mobile-order-1 {
    order: 2;
  }
  .item3 {
    padding: 0px 30px 0px 30px;
  }
  .item4 {
    padding: 40px 30px 0px 30px;
  }
  .mobile-order-2 {
    order: 1;
  }
  .screen1 {
    display: none;
  }
  .touch1 {
    display: none;
  }
  .product-content {
    padding: 14px 20px 10px 20px;
  }
  .imagesectionproduct {
    padding: 0px 20px 14px 20px;
  }
  .product {
    border-radius: 14px;
  }
  .product2 {
    margin: 40px auto 0px auto;
    padding: 14px 20px 20px 20px;
  }
  .partition {
    margin: 10px 0px 20px 0px;
    max-width: 100%;
  }
  .dispayResponse {
    display: none !important;
  }
  .ordernow2 {
    margin: 18px 10px 10px 10px !important;
    width: 100%;
  }
  .dispayhide {
    display: block !important;
  }
  .dispayhideMobile {
    display: none !important;
  }
  .customfeild_text2 {
    max-width: 100%;
    padding-left: 0px;
  }
  .item9 {
    padding: 0px 30px;
  }
  .item10 {
    padding: 20px 30px;
  }
  .aboutLetter {
    justify-content: left;
    padding: 0px 30px;
    margin-top: 54px !important;
  }
  .item20 {
    padding: 10px 30px;
  }
  .item21 {
    padding: 10px 30px;
  }
  .ordernow3 {
    margin: 0px;
    width: 100%;
  }
  .logoDesign {
    width: 300px;
    height: auto;
  }
  .buttoncontain {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .ordernow4 {
    width: 100%;
  }
  .marginSet {
    padding: 20px 30px;
  }
  .instaContainer {
    padding: 10px 30px 10px 30px;
  }
  .divider_std_footer {
    margin: 10px 30px;
  }
  .endtext {
    padding: 10px 30px 10px 30px;
    line-height: 22px;
  }
  .feildtext {
    max-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-left: 0px;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .customBox {
    width: 300px;
  }
  .customscroll {
    max-height: calc(100vh - 180px);
  }
}
@media (min-width: 600px) {
  .customBox {
    width: 450px;
  }
}
@media (min-width: 1200px) {
  .menuCustom {
    display: none !important;
  }
}
.menuCustom {
  color: #fff;
  margin-right: 10px;
}

.logo {
  height: 100%;
}

.menuCustom {
  font-size: 2rem;
  cursor: pointer;
}

.center_align {
  display: flex;
  align-items: center;
}

.divider_std {
  margin-left: 10px;
}

.navigation ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .listbutton {
  margin: 0 15px;
  cursor: pointer;
} */

.ordernow {
  margin-left: auto;
}

.drawerContainer {
  width: 100%;
  background-color: #a83232;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.closeButton {
  align-self: flex-end;
}
.buttonSend.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.rMark{
  position: absolute;
  top: 6px;
  left: 92px;
  font-size: 10px;
  color: #fff;
}
@media (min-width: 599px) {
  .rMark{
    position: absolute;
    top: 6px;
    left: 84px;
    font-size: 10px;
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .rMark{
    position: absolute;
    top: 6px;
    left: 92px;
    font-size: 10px;
    color: #fff;
  }
}
.logo2 {
  width: 96px;
  height: auto;
  margin-right: 2px;
  position: absolute;
  bottom: 3px;
}
@media (max-width: 700px) {
  .logo2 {
    width: 65px;}
}