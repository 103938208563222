
@font-face {
  font-family: 'Montserrat';
  src: url('../src/Fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html body,
#root {
  font-family: 'Montserrat', sans-serif !important;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../src/Fonts/Montserrat-SemiBold.ttf') format('truetype'); /* Add the path to the Montserrat bold font file */
  font-weight: bold; /* Specify the bold font weight */
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
  padding-right: 0px !important;
  padding-left: 0px !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
